import * as React from "react";

import AccountForm from "../components/Account/AccountForm";
import AuthContent from '../components/Login/AuthContent';
import Layout from "../components/Base/Layout";

export default function LogIn() {
  
  return (
    <Layout>
      <AuthContent>
        <AccountForm />
      </AuthContent>
    </Layout>
  );
}
