import * as React from "react";
import { useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { useToast, Button, Box } from "@chakra-ui/react";

import { GET_USER } from "../hooks/useAuth";
import { navigate } from "gatsby";

const LOG_OUT = gql`
  mutation logOut {
    logout(input: {}) {
      status
    }
  }
`;

const LogOut:React.FC = () => {
  const toast = useToast;
  const [logOut, { called, loading, error, data }] = useMutation(LOG_OUT, {
    refetchQueries: [
      { query: GET_USER }
    ],
  });
  const loggedOut = Boolean(data?.logout?.status);

    function handleClick() {
       logOut()
       navigate('/log-in');
  }

    return (
        <Box pt="32px" display="flex" justifyContent="center">
            <Button onClick={() => handleClick()}> Log Out </Button>
        </Box>
  );
}

export default LogOut