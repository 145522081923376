import * as React from "react";
import { useEffect, ReactNode } from "react";
import { navigate } from "gatsby";

import useAuth from "../hooks/useAuth";
import Loading from "./Loading"
import { useToast } from '@chakra-ui/react';

export default function AuthContent({ children }: { children: ReactNode }) {
  const { loggedIn, loading } = useAuth();
  const toast = useToast()

  // Navigate unauthenticated users to Log In page.
  useEffect(() => {
    if (loading && !loggedIn) {
      navigate('/log-in');
    }
  }, [loggedIn, loading, navigate]);

  if (loggedIn) {
    return <>{children}</>;
  }

  return <Loading></Loading>;
}
